import React from "react"

const Logo = ({ size, opacity }) => {
  const logo = require("../../images/logo.svg")
  const logoStyle = ["flex", "items-center", "justify-center"]

  switch (size) {
    case "sm": {
      logoStyle.push("h-8")
      break
    }
    case "md": {
      logoStyle.push("h-12")
      break
    }
    case "lg":
    default: {
      logoStyle.push("h-24")
      break
    }
  }

  return (
    <div
      style={{ opacity: opacity, transition: "all ease-in-out .5s" }}
      className={logoStyle.join(" ")}
    >
      <img className={["h-full", "w-full"].join(" ")} src={logo}></img>
    </div>
  )
}

Logo.defaultProps = {
  size: "sm",
  opacity: 1,
}

export default Logo
