import React from "react"
import ControlButton from "../Button/ControlButton"
import VideoPlayer from "./VideoPlayer"

const BackgroundVideo = ({ src}) => {
  const videoContainerStyle = [
    // "w-full",
    "h-full",
    "flex",
    "items-center",
    "justify-center",
    "relative",
    "bg-white",
  ]

  if (src.length) {
    const videos = []
    src.forEach((video, index) => {
      videos.push(
        <VideoPlayer
          className="max-w-none"
          key={index}
          src={video}
          loop={true}
          autoplay={true}
          muted={true}
          controls={false}
          isPlaying={true}
          isMuted={true}
        />
      )
    })

    return (
      <div className={videoContainerStyle.join(" ")}>
        {videos}
      </div>
    )
  }

  return null
}

export default BackgroundVideo
