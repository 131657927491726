import React, { useState } from "react"
import Frame from "../components/Frame/Frame"
import Symbol from "../components/Symbol/Symbol"

import Masthead from "../components/Masthead/Masthead"
import Navigation from "../components/Navigation/Navigation"
import Popup from "../components/Popup/Popup"
import BackgroundVideo from "../components/Video/BackgroundVideo"
import VideoPlayer from "../components/Video/VideoPlayer"
import VideoControls from "../components/Video/VideoControls"

export default () => {
  const [activePage, setActivePage] = useState()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [hasFullscreen, setHasFullscreen] = useState(false)

  // Handle functions
  const handlePageChange = pageName => {
    console.log(pageName)
    setActivePage(pageName)
    if (pageName === "Show Reel") {
      setIsPlaying(true)
    }
  }

  const handleStatusUpdate = evt => {
    console.log(evt, evt.type)
    if (evt.type === "ended") {
      setIsPlaying(false)
    }
  }

  const handleVideoChange = change => {
    switch (change) {
      case "play": {
        setIsPlaying(!isPlaying)
        break
      }
      case "mute": {
        setIsMuted(!isMuted)
        break
      }
      case "fullscreen": {
        setHasFullscreen(!hasFullscreen)
        break
      }
      case "close": {
        setIsPlaying(false)
        setActivePage("")
      }
    }
  }

  return (
    <div
      className={["relative", "w-screen", "h-screen", "overflow-hidden"].join(
        " "
      )}
    >
      <Masthead title={"Kiekste Wedding"} active={activePage !== "Show Reel"} />

      <Frame backgroundImage={""} padding={false}>
        <BackgroundVideo
          src={["loop2.mp4"]}
          handleChange={handlePageChange}
          isActive={activePage === "Show Reel"}
        ></BackgroundVideo>

        <Popup
          backgroundImage={""}
          handleChange={handlePageChange}
          from="bottom"
          isActive={activePage === "Show Reel"}
          hasPadding={false}
          hasCloseButton={false}
          isBackdrop={true}
        >
          <VideoPlayer
            src={"reel.mp4"}
            isPlaying={isPlaying}
            controls={true}
            muted={isMuted}
            hasFullscreen={hasFullscreen}
            onStatusChange={handleStatusUpdate}
          />
          <VideoControls
            isActive={true}
            handleChange={handleVideoChange}
            isPlaying={isPlaying}
            isMuted={isMuted}
            hasFullscreen={hasFullscreen}
          />
        </Popup>
        <Popup
          backgroundImage={""}
          handleChange={handlePageChange}
          from="bottom"
          isActive={activePage === "Über uns"}
        >
          <h2 className={["font-serif", "text-3xl", "pb-4"].join(" ")}>
            Über uns
          </h2>
          <div style={{ color: "#636363" }}>
            <p className={["pb-8"].join(" ")}>
              Hey ihr Lieben, wir sind Kenny & Basti und wir begleiten den
              schönsten Tag eures Lebens und halten die Highlights genauso wie
              die kleinen intimen Momente für immer fest. Die
              Hauptdarstellerseid immer ihr! Und das sollen alle sehen.
            </p>
            <p className={["pb-8"].join(" ")}>
              Wir freuen uns euch kennenzulernen und mit euch zusammen euer
              Hochzeitsvideo zu planen.
            </p>
            <p>Kontaktiert uns gern. :)</p>
          </div>
        </Popup>
        <Popup
          backgroundImage={""}
          handleChange={handlePageChange}
          from="bottom"
          isActive={activePage === "Kontakt"}
          footer={
            <ul
              className={[
                "flex",
                "flex-row",
                "items-end",
                "justify-end",
                "p-2",
                "lg:p-4",
              ].join(" ")}
            >
              <li
                className={[
                  "h-8",
                  "w-8",
                  "flex",
                  "items-center",
                  "justify-center",
                  "m-2",
                ].join(" ")}
              >
                <a
                  href={"https://www.instagram.com/kiekste_wedding/"}
                  title="Kiekste Wedding auf Instagram"
                  target="_blank"
                >
                  <Symbol
                    name="instagram"
                    color="#636363E5"
                    style="brands"
                    size="2x"
                  />
                </a>
              </li>
              <li
                className={[
                  "h-8",
                  "w-8",
                  "flex",
                  "items-center",
                  "justify-center",
                  "m-2",
                ].join(" ")}
              >
                <a
                  href={"https://www.facebook.com/KIEKSTEberlin.de/"}
                  title="Kiekste Wedding auf Facebook"
                  target="_blank"
                >
                  <Symbol
                    name="facebook"
                    color="#636363E5"
                    style="brands"
                    size="2x"
                  />
                </a>
              </li>
            </ul>
          }
        >
          <h2
            className={[
              "font-serif",
              "text-3xl",
              "text-center",
              "lg:text-left",
            ].join(" ")}
          >
            Sebastian Friedl
          </h2>
          <div>
            <p
              className={[
                "pb-2",
                "lg:pb-8",
                "text-center",
                "lg:text-left",
              ].join(" ")}
              style={{ color: "#636363" }}
            >
              Beratung • Konzeption • Design
            </p>
            <ul>
              <li
                className={[
                  "flex",
                  "flex-col",
                  "lg:flex-row",
                  "pb-2",
                  "leading-snug",
                ].join(" ")}
              >
                <span className={["mr-2"].join(" ")}>
                  <Symbol
                    name="envelope"
                    color="#636363"
                    style="light"
                    size="2x"
                  />
                </span>
                <span
                  className={["flex", "justify-center", "items-center"].join(
                    " "
                  )}
                >
                  <a
                    className={["break-words"].join(" ")}
                    title="sebastian.friedl@kiekste-berlin.de"
                    href="mailto:sebastian.friedl@kiekste-berlin.de"
                    dangerouslySetInnerHTML={{
                      __html: "sebastian.friedl&shy;@kiekste-berlin.de",
                    }}
                  />
                </span>
              </li>
              <li
                className={[
                  "flex",
                  "flex-col",
                  "lg:flex-row",
                  "pb-2",
                  "leading-snug",
                ].join(" ")}
              >
                <span className={["mr-2"].join(" ")}>
                  <Symbol
                    name="phone"
                    color="#636363"
                    style="light"
                    size="2x"
                  />
                </span>
                <span
                  className={["flex", "justify-center", "items-center"].join(
                    " "
                  )}
                >
                  <a
                    title="+ 49 162 94 90 709"
                    href="tel:00491629490709"
                    dangerouslySetInnerHTML={{
                      __html: "+ 49 162 94 90 709",
                    }}
                  />
                </span>
              </li>
              <li
                className={[
                  "flex",
                  "flex-col",
                  "lg:flex-row",
                  "pb-2",
                  "leading-snug",
                ].join(" ")}
              >
                <span className={["mr-2"].join(" ")}>
                  <Symbol
                    name="globe"
                    color="#636363"
                    style="light"
                    size="2x"
                  />
                </span>
                <span
                  className={["flex", "justify-center", "items-center"].join(
                    " "
                  )}
                >
                  <a
                    title="https://www.kiekste-berlin.de"
                    href="https://www.kiekste-berlin.de"
                    dangerouslySetInnerHTML={{
                      __html: "kiekste-berlin.de",
                    }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </Popup>
      </Frame>

      <Navigation
        handleChange={handlePageChange}
        activePage={activePage}
        isVisible={activePage !== "Show Reel"}
      />
    </div>
  )
}
