import React from "react"

// Components
import CloseButton from "../Button/ControlButton"

// Styles
import styles from "./popup.scss"

const Popup = ({
  children,
  backgroundImage,
  handleChange,
  from,
  isActive,
  footer,
  hasCloseButton,
  hasPadding,
  isBackdrop,
}) => {
  const imgSrc = backgroundImage
    ? require("../../images/" + backgroundImage)
    : ""

  const popupContainerStyle = [
    styles.Popup,
    "absolute",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "pt-36",
    "pb-28",
    "px-8",
    "flex",
    "justify-center",
    "items-center",
  ]

  const popupStyle = [
    "overflow-hidden",
    "bg-opacity-70",
    "flex-shrink-0",
    "relative",
    "w-full",
  ]

  const popupContentStyle = []

  if (hasPadding) {
    popupContentStyle.push(
      "pt-12",
      "pr-4",
      "pb-4",
      "pl-4",
      "lg:pt-16",
      "lg:pr-12",
      "lg:pb-12",
      "lg:pl-12"
    )
    popupStyle.push("h-full","sm:h-2/3", "xl:h-1/2", "sm:w-1/2", "md:w-1/2", "lg:w-1/3")
  } else {
    popupStyle.push("lg:w-1/2")
  }

  let style = {
    transition:
      "transform ease-in-out .5s, opacity ease-in-out .25s, background-color 0.5s 0.25s ease-in-out",
    opacity: "0",
  }

  switch (from) {
    case "left": {
      style.transform = "translateX(-200%)"
      break
    }
    case "right": {
      style.transform = "translateX(200%)"
      break
    }
    case "bottom": {
      style.transform = "translateY(200%)"
      break
    }
  }

  if (isActive) {
    style.transform = "translate(0,0)"
    style.opacity = "100"
    style.backgroundColor = isBackdrop ? "black" : "transparent"
  }

  return (
    <div className={popupContainerStyle.join(" ")} style={style}>
      <div
        className={popupStyle.join(" ")}
        style={{
          boxShadow: "0 2px 2px 0 rgba(0,0,0,.16), 0 0 0 1px rgba(0,0,0,.08)",
          backgroundColor: "rgba(243,225,225,0.7)",
        }}
      >
        <div
          className={[
            "top-2",
            "right-2",
            "lg:top-4",
            "lg:right-4",
            "z-10",
            "absolute",
          ].join(" ")}
        >
          {hasCloseButton && (
            <CloseButton title={"✕"} handleClick={handleChange}></CloseButton>
          )}
        </div>
        <div
          className={["h-full", "overflow-x-hidden", "overflow-y-auto"].join(
            " "
          )}
        >
          <div className={popupContentStyle.join(" ")}>{children}</div>
        </div>
        <div
          className={[
            "absolute",
            "bottom-0",
            "right-0",
            "flex",
            "justify-end",
          ].join(" ")}
        >
          {footer}
        </div>
      </div>
    </div>
  )
}

Popup.defaultProps = {
  hasCloseButton: true,
  hasPadding: true,
  isBackdrop: false,
}

export default Popup
