import React from "react"
import NavigationItem from "./NavigationItem"

const navigationOptions = [
  { label: "Kontakt" },
  { label: "Show Reel" },
  { label: "Über uns" },
]

const Navigation = ({ children, handleChange, isVisible, activePage }) => {
  const navigationContainerStyle = [
    "fixed",
    "bottom-0",
    "left-0",
    "w-full",
    "flex",
    "items-center",
    "justify-center",
    "pb-4",
    "lg:pb-12",
    "z-30",
    "flex-col",
    "bg-gradient-to-t from-white",
  ]

  const navigationStyle = []
  const navigationListStyle = ["flex", "flex-row"]

  const renderNavigationOptions = options => {
    return options.map((opt, index) => {
      return (
        <NavigationItem
          key={index}
          as="li"
          title={opt.label}
          active={activePage === opt.label}
          handleChange={handleChange}
        />
      )
    })
  }

  // Handle function
  const handleClick = () => {
    handleChange("")
  }

  return (
    <div
      className={navigationContainerStyle.join(" ")}
      style={
        isVisible
          ? {
              transform: "translateY(0)",
              transition: "all ease-in-out .5s",
            }
          : {
              transform: "translateY(200px)",
              transition: "all ease-in-out .5s",
            }
      }
    >
      <div className={navigationStyle.join(" ")}>
        <ul className={navigationListStyle.join(" ")}>
          {renderNavigationOptions(navigationOptions)}
        </ul>
        {children}
      </div>

      {/* <div
        onClick={() => handleClick()}
        className={[
          "h-24",
          "w-24",
          "border-2",
          "border-white",
          "rounded-full",
          "bg-white",
          "p-4",
          "flex",
          "justify-center",
          "items-center",
          "flex-col",
          "lg:hidden",
        ].join(" ")}
      >
        <span
          className={["h-2", "w-full", "bg-pink-500", "rounded-xl", "m-1"].join(
            " "
          )}
        ></span>
        <span
          className={["h-2", "w-3/4", "bg-pink-500", "rounded-xl", "m-1"].join(
            " "
          )}
        ></span>
        <span
          className={["h-2", "w-1/2", "bg-pink-500", "rounded-xl", "m-1"].join(
            " "
          )}
        ></span>
      </div> */}
    </div>
  )
}

export default Navigation
