import React from "react"
import Symbol from "../Symbol/Symbol"

const ControlButton = ({ handleClick, title, size, symbol }) => {
  const buttonContainerStyle = ["m-2"]
  const buttonStyle = [
    "rounded-full",
    "bg-gray-200",
    "h-full",
    "w-full",
    "flex",
    "items-center",
    "justify-center",
    "opacity-80",
  ]

  switch (size) {
    case "sm":
    default: {
      buttonContainerStyle.push("h-8", "w-8")
      break
    }
    case "md": {
      buttonContainerStyle.push("h-16", "w-16")
      break
    }
    case "xl": {
      buttonContainerStyle.push("h-24", "w-24")
      buttonContainerStyle.push("text-2xl")
      break
    }
  }

  return (
    <div className={buttonContainerStyle.join(" ")}>
      <button onClick={handleClick} className={buttonStyle.join(" ")}>
        {title && title}
        {symbol && (
          <Symbol name={symbol} color="#636363E5" style="light" size="sm" />
        )}
      </button>
    </div>
  )
}

ControlButton.defaultProps = {
  size: "sm",
}

export default ControlButton
