import React, { useRef, useState, useEffect } from "react"

const VideoPlayer = ({
  src,
  isPlaying,
  className,
  autoplay,
  controls,
  muted,
  loop,
  onStatusChange,
  hasFullscreen,
}) => {
  const videoRef = useRef()

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("fullscreenchange", evt => {
        console.log(evt)
      })
    }
  }, [])

  if (src) {
    const videoSrc = require("../../videos/" + src)
    return (
      <video
        className={className ? className : ["w-full", "h-full"].join(" ")}
        style={
          hasFullscreen
            ? { position: "fixed", top: "0", left: "0", width: "100%" }
            : {}
        }
        ref={videoRef}
        src={videoSrc}
        loop={loop}
        autoPlay={autoplay}
        muted={muted}
        playsInline={true}
        controls={controls}
        onPlay={evt => onStatusChange(evt)}
        onPause={evt => onStatusChange(evt)}
        onEnded={evt => onStatusChange(evt)}
        onVolumeChange={evt => onStatusChange(evt)}
      ></video>
    )
  }
  return null
}

VideoPlayer.defaultProps = {
  autoplay: false,
  onStatusChange: () => {},
}

export default VideoPlayer
