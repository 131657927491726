import React from "react"

// Styles
import styles from "./Navigation.module.scss"

const NavigationItem = ({ as, title, active, handleChange }) => {
  const navigationElementStyle = [
    styles.NavItem,
    "text-black",
    "flex",
    "flex-col",
    "items-center",
    "content-center",
    "mx-4",
    "my-0",
    "cursor-pointer",
    "lg:text-xl",
    "uppercase",
    "font-serif"
  ]

  if (!active) {
    navigationElementStyle.push("text-opacity-80")
  } else {
    navigationElementStyle.push("font-bold")
  }

  const Tag = as

  return (
    <Tag className={navigationElementStyle.join(" ")}>
      <div>
        <a onClick={() => handleChange(title)} className={["my-2"].join(" ")}>
          {title}
        </a>
      </div>

      <div
        className={["relative", "mt-2"].join(" ")}
        style={{
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: active ? "black" : "transparent",
          width: "30px",
          height: "2px",
        }}
      ></div>
    </Tag>
  )
}

export default NavigationItem
