import React, { useState } from "react"

// Components
import ControlButton from "../Button/ControlButton"

const VideoControls = ({
  handleChange,
  isActive,
  isPlaying,
  isMuted,
  hasFullscreen,
}) => {
  const videoControlStyle = [
    "fixed",
    "flex",
    "bottom-0",
    "left-0",
    "pb-8",
    "lg:pb-12",
    "z-20",
    "w-full",
    "items-center",
    "justify-center",
  ]

  return (
    <div
      style={
        isActive
          ? {
              transform: "translateY(0)",
              transition: "all ease-in-out .5s",
            }
          : {
              transform: "translateY(200px)",
              transition: "all ease-in-out .5s",
            }
      }
      className={videoControlStyle.join(" ")}
    >
      <ControlButton
        symbol={"times"}
        handleClick={() => handleChange("close")}
      ></ControlButton>
      <ControlButton
        symbol={isPlaying ? "pause" : "play"}
        handleClick={() => {
          handleChange("play")
        }}
      ></ControlButton>
      <ControlButton
        symbol={isMuted ? "volume-slash" : "volume"}
        handleClick={() => {
          handleChange("mute")
        }}
      ></ControlButton>
      <ControlButton
        symbol={hasFullscreen ? "compress-wide" : "expand"}
        handleClick={() => {
          handleChange("fullscreen")
        }}
      ></ControlButton>
    </div>
  )
}

export default VideoControls
