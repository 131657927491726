import React from "react"

const Frame = ({ children, backgroundImage, padding }) => {
  const imgSrc = backgroundImage
    ? require("../../images/" + backgroundImage)
    : ""

  const frameStyle = [
    "frame",
    "w-full",
    "h-full",
    "bg-no-repeat",
    //"bg-cover",
    "flex",
    "bg-center",
    "flex-col",
    "justify-end",
    "items-center",

    "relative",
  ]

  if (padding) {
    frameStyle.push("py-36", "px-10")
  }

  return (
    <div
      className={frameStyle.join(" ")}
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      {children}
    </div>
  )
}

Frame.defaultStyle = {
  padding: true,
}

export default Frame
