import React from "react"

// Components
import Logo from "../Logo/Logo"

const Masthead = ({ title, active }) => {
  const mastheadStyle = [
    "p-4",
    "absolute",
    "top-0",
    "left-0",
    "w-full",
    "z-10",
    "flex",
    "items-center",
    "justify-center",
    "p-4",
  ]

  if(active) {
    mastheadStyle.push("scale-100")
  } else {
    mastheadStyle.push("scale-100")
  }

  const headlineStyle = ["font-bold", "text-white", "pt-4", "tracking-widest"]

  const mastheadContainerStyle = [
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
  ]

  if (!active) {
    headlineStyle.push("text-lg", "text-opacity-80")
  } else
  {
    headlineStyle.push("text-3xl")
  }

  return (
    <div className={mastheadStyle.join(" ")} style={{}}>
      <div className={mastheadContainerStyle.join(" ")}>
        <Logo size={active ? "lg" : "md"} opacity={active ? 1 : 0.8}></Logo>
        {/* <h1 className={headlineStyle.join(" ")} style={{transition: "all ease-in-out .5s"}}>{title}</h1> */}
      </div>
    </div>
  )
}

Masthead.defaultProps = {
  active: true,
}

export default Masthead
