import React from "react"
import PropTypes from "prop-types"

// Components
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
library.add(fal, fas, fab)

class Symbol extends React.Component {
  render() {
    const { style, name, size, spinning, className, color } = this.props

    let faStyle = ""
    switch (style) {
      case "light":
        faStyle = "fal"
        break
      case "brands":
        faStyle = "fab"
        break
      default:
        faStyle = "fas"
    }
    return (
      <span
        className={[
          style.Symbol,
          "p-1",
          "flex",
          "justify-center",
          "items-center",
          className,
        ].join(" ")}
      >
        <FontAwesomeIcon
          color={color}
          spin={spinning}
          icon={[faStyle, name]}
          size={size}
        />
      </span>
    )
  }
}

Symbol.propTypes = {
  style: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  spinning: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
}

Symbol.defaultProps = {
  style: "light",
  size: "1x",
  spinning: false,
  color: "#000000",
  name: "music",
}

export default Symbol
